.filepond--file-status {
  flex-shrink: 1 !important;
  padding-left: 20px !important;
}
/* class="filepond--file-info-sub" */

@media (max-width: 760px) {
  .filepond--file-info-sub {
    display: none;
  }
}
